/*
  Estilos globales
  Deben de estar dentro de ng-deep, no se abuse de esta caracteristica, solo para estilos y clases globales.
*/
$tituloAzul: #252688;

::ng-deep {
  *:not(.mat-icon) {
    font-family: "Nunito Sans" !important;
  }

  // CHUBB BUTTON
  .chubb-btn {
    width: 100% !important;
    text-align: center !important;
    border-radius: 8px !important;
    font-size: 13pt !important;
    font-weight: 700 !important;
    font-family: "Nunito Sans", sans-serif !important;
    background-color: #f7f7f7;
    color: #d2006e;
    padding: 11px 3vw !important;
    margin: 5px 0 !important;
    @media (min-width: 992px) {
      width: auto !important;
    }
  }

  .chubb-btn-magenta {
    color: #fff !important;
    background-color: #de0080 !important;
    border: 1px solid #fff !important;
    opacity: 1;
  }

  .chubb-btn-magenta.mat-button-disabled {
    background-color: #c63890 !important;
    opacity: 0.5;
  }

  .modal-fix-percentage {
    mat-bottom-sheet-container.mat-bottom-sheet-container {
      max-height: 94vh;
    }
  }
  mat-bottom-sheet-container.mat-bottom-sheet-container {
    max-height: 94vh;
  }
}
