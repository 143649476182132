@import "./custom-theme.scss";
@import "./modular-theme.scss";
@font-face {
  font-family: "Roboto Medium";
  src: url("./assets/scss/chubb-theme/fonts/Roboto-Medium.ttf")
    format("truetype");
}
@font-face {
  font-family: "Roboto Regular";
  src: url("./assets/scss/chubb-theme/fonts/Roboto-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "Roboto Light";
  src: url("./assets/scss/chubb-theme/fonts/Roboto-Light.ttf")
    format("truetype");
}
@font-face {
  font-family: "Roboto Thin";
  src: url("./assets/scss/chubb-theme/fonts/Roboto-Thin.ttf")
    format("truetype");
}
@font-face {
  font-family: "Roboto Bold";
  src: url("./assets/scss/chubb-theme/fonts/Roboto-Bold.ttf")
    format("truetype");
}
$light-cyan: #eff8ff;
$cyan: #1761b3;
$darkgray: rgba(175, 175, 175, 1);

:root {
  --bg-magenta: #bc157c;
  --bg-magenta-opacity: rgba(188, 21, 124, 0.43);
  --bg-darkblue: #14123d;
  --swiper-theme-color: #193181 !important;
}
img {
  width: auto;
  height: auto;
}
p {
  font-weight: 300;
}
a, .link-blue {
  color: #193181;
  font-size: 0.9375rem;
  font-family: "Lato", sans-serif;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}
strong, .no-bstrp strong {
  font-weight: 700;
}
code {
  color: #e01a76;
}
home-bg-color {
  color: #f4f6f8;
}

.text-prewrap {
  white-space: pre-wrap;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
}
.app-container > :last-child {
  display: block;
  min-height: calc(100vh - 56px);
}
/* Set input fields' text size to 16px to avoid auto zoom in iOS */
input {
  font-size: 16px;
}
.bg-md-primary-blue {
  background-color: #150f96;
  color: #ffffff;
}
.bg-compare-blue {
  background-color: #00165f;
  color: #ffffff;
}

.bg-cyan {
  background-color: $cyan;
  color: rgba(255, 255, 255, 1);
  .mat-stroked-button {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, 1);
  }
}
.bg-darkgray {
  background-color: $darkgray;
  color: rgba(255, 255, 255, 1);
  .mat-stroked-button {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, 1);
  }
}

.bg-gray {
  background-color: #f4f6f8;
}
.bg-light-cyan {
  background-color: $light-cyan;
}

.descarga-pdf {
  margin-bottom: 10px;
  text-align: center;
}

.color-mat-icon {
  color: rgb(30, 49, 142);
}

.headtitle {
  white-space: pre-wrap;
}

.timepicker-backdrop-overlay,
.timepicker-overlay {
  z-index: 1001 !important;
}

button.no-fixed-width {
  min-width: unset;
}
.mat-focused {
  .mat-form-field-required-marker {
    color: #150f96;
  }
}

.title-step {
  background-color: #f4f6f8;
  padding-bottom: 2.0625rem;
  padding-top: 2.0625rem;
  p {
    font-weight: normal;
    margin: 0;
  }
}

.mx-1rem {
  margin-left: -1rem;
  margin-right: -1rem;
}

.payitem-container {
  .mat-button-wrapper {
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    justify-content: space-between;
  }
}

.checkbox-text {
  white-space: break-spaces;
}
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .custom-modalbox {
    height: 100%;
  }
}

.buzon-cobro {
  background: royalblue;
  color: white;
  padding-left: 8px;
  font-size: 7pt;
  border-radius: 0.2rem;
  padding-right: 8px;
  line-height: 20px;
  transform: skew(20deg);
  margin-right: 4px;
}

.method-pay {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.method-legacy {
  padding-bottom: 0px !important;
}

.margin-img-legacy {
  margin-bottom: 7.008px;
}

.account {
  margin-bottom: 13px !important;
}

.buzon-pay {
  margin: 30px;
}

.scroll-container {
  ol,
  ul {
    padding-left: 20px;
  }
  a {
    text-decoration: underline;
  }
}

.bottom-sheet-info-list-container {
  box-shadow: 0px 3px 6px #00000029;
  height: 94vh; //36.375rem;
  &.mat-bottom-sheet-container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-height: 94vh;
  }
}
.mat-bottom-sheet-container {
  max-height: 94vh !important;
}
.bottom-sheet-events-container {
  box-shadow: 0px 3px 6px #00000029;
  height: 88vh;
  &.mat-bottom-sheet-container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-height: 88vh;
  }
}
.dialog-announcement-container .mat-mdc-dialog-container {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background-color: transparent;
    box-shadow: none;
    overflow: visible;
  }
}

@import "../src/assets/scss/main.scss";

/* legacy styles */
.legacy-container,
.panel-mobile {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0 1.5rem;
    &::before {
      display: none;
    }
  }
  .btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
  }
  .mat-icon.mat-tooltip-trigger:hover {
    cursor: pointer;
  }

  a.mat-flat-button.mat-accent.schedule-us-btn {
    color: #ffffff;
    &:hover {
      text-decoration: none;
    }
  }

  button[mat-icon-button] .mat-button-focus-overlay {
    background-color: transparent;
  }

  a[mat-button],
  button[mat-button],
  a[mat-flat-button],
  button[mat-flat-button],
  a[mat-raised-button],
  button[mat-raised-button],
  a[mat-stroked-button],
  button[mat-stroked-button],
  .bt-style-bg {
    border-radius: 5rem;
    font-size: 1.125rem;
    font-weight: normal;
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
    max-width: 19.6875rem;
    width: 100%;
    .mat-button-wrapper {
      font-size: 1.125rem;
    }
    &.plan-view-more,
    &.edit-additional-step {
      line-height: unset;
      .mat-button-wrapper {
        font-size: 15px;
      }
    }
  }
  button[color="accent"].mat-flat-button.mat-accent,
  a[color="accent"].mat-flat-button.mat-accent {
    color: rgba(255, 255, 255, 1);
    .mat-button-wrapper {
      color: inherit;
    }
  }
  button[color="accent"][mat-flat-button][disabled]:disabled {
    background-color: #80e0eb;
    color: #ffffff;
  }

  .mat-ripple-element {
    transition-duration: 0ms !important;
  }
  button[mat-stroked-button][color="warn"] {
    border-color: #be0000;
  }
}
/* end*/

/* Global Override Styles */
.bottom-sheet-info-list-container {
  box-shadow: 0px 3px 6px #00000029;
  height: 94vh;
  &.mat-bottom-sheet-container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-height: 94vh;
  }
}
.mat-bottom-sheet-container {
  max-height: 94vh !important;
}

/* MAT RADIO BUTTON COLOR */
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d2006e;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: #d2006e;
  background-color: #d2006e;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #d2006e;
}

#consent-of-uses-modal {
  color: #007bff;
  text-decoration: underline;
}
mat-icon.mat-icon {
  overflow: visible;
}
.mat-datepicker-content .mat-datepicker-content-container-with-custom-header .mat-calendar[style] {
  height: 354px;
}
.dialog-modal-form-container {
  .dialog-form-header {
    padding-top: 1rem!important;
  }
  .dialog-form__fixed-bottom {
    padding-bottom: 90px!important;
    @media screen and (min-width: 992px) {
      padding-bottom: 0!important;
    }
  }
}
.dialog-modal-list-container {
  .header {
    padding-top: 1rem!important;
  }
}
.continue-form__quote-dialog {
  .forma-cotizacion-dialog {
    &__content {
      padding: 1.5rem 1rem!important;
    }
    &__markup {
      --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
      color: var(--mdc-dialog-supporting-text-color);
    }
  }
}
.dialog-info-list-container {
  .dialog-info-header {
    padding-top: 1rem!important;
  }
}
.dialog-modal-action-container {
  .header {
    padding-top: 1rem!important;
  }
}
.dialog-error-container {
  .dialog {
    margin: 0!important;
  }
}
chubb-components-simple-loader .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #d2006e;
}
.links-informative__coverages-exclusions {
  .container-cobertura__w-cta__fixed-bottom {
    height: calc(95vh - 320px)!important;
    @media screen and (min-width: 992px) {
      height: 380px!important;
    }
  }
}

.testimonials__button-container.only-mobile {
  .testimonials__button-cta {
    width: calc(100% - 1.5rem) !important;
  }
}
.bottom-sheet-error-container .mat-bottom-sheet-container {
  padding: 0;
}
chubb-components-quote-data .quote-data__simple-title {
  padding-bottom: 10px;
}
chubb-components-card-list .mat-mdc-button .mdc-button__label, .mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-raised-button .mdc-button__label, .mat-mdc-outlined-button .mdc-button__label {
  z-index: 0!important;
}
chubb-components-links-informative.fix-link {
  .link {
    text-wrap: nowrap;
  }
}
chubb-components-landing.fix-vw {
  @media (min-width: 992px) {
    .landing {
      height: 46vw;
    }
  }
}